import { Center, SimpleGrid } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { STATES } from "../../../constants/Wholesale";
import { isDesktopHook } from "../../../Hooks";
import { InputField } from "../../common/form_fields/InputField";
import { SelectField } from "../../common/form_fields/SelectField";
import { Break } from "../../styled/Break";
import { Title } from "../../styled/Text";
import { FormikStep } from "../FormikStepper";

export function Page2({ validationSchema }: { validationSchema: any }): ReactElement {
  const isDesktop = isDesktopHook();

  return (
    <FormikStep label="Address Information" submitDisabled={false} color={"wholesale.primary"} colorHover={"wholesale.primaryHover"}>
      <Title text="Wholesale Application" fontSize={isDesktop ? "2.3rem" : "2rem"} />
      <Center>
        <InputField
          label="Billing Address"
          name="billingAddress"
          width="80vw"
          borderHoverColor="wholesale.primaryHover"
          borderColor="wholesale.primary"
        />
      </Center>
      <SimpleGrid columns={{ lg: 3, sm: 1 }} gap={4}>
        <InputField label="City" name="billingCity" borderHoverColor="wholesale.primaryHover" borderColor="wholesale.primary" />
        <SelectField
          options={STATES}
          label="State"
          placeholder=" "
          name="billingState"
          borderHoverColor="wholesale.primaryHover"
          borderColor="wholesale.primary"
          height="4.9vh"
        />
        <InputField label="Zip Code" name="billingZip" borderHoverColor="wholesale.primaryHover" borderColor="wholesale.primary" />
      </SimpleGrid>
      <Break height="5vh" />
      <Center>
        <InputField
          label="Shipping Address"
          name="shippingAddress"
          width="80vw"
          borderHoverColor="wholesale.secondaryHover"
          borderColor="wholesale.secondary"
        />
      </Center>
      <SimpleGrid columns={{ lg: 3, sm: 1 }} gap={4}>
        <InputField label="City" name="shippingCity" borderHoverColor="wholesale.secondaryHover" borderColor="wholesale.secondary" />
        <SelectField
          label="State"
          name="shippingState"
          options={STATES}
          borderHoverColor="wholesale.secondaryHover"
          placeholder=" "
          borderColor="wholesale.secondary"
          height="4.9vh"
        />
        <InputField label="Zip Code" name="shippingZip" borderHoverColor="wholesale.secondaryHover" borderColor="wholesale.secondary" />
      </SimpleGrid>
      <Break height="5vh" />
    </FormikStep>
  );
}
