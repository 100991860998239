import { Box } from "@chakra-ui/react";
import { navigate } from "gatsby";
import React, { ReactElement } from "react";
import { object } from "yup";
import { create } from "../../../api/common";
import {
  STRING_VALIDATION,
  EMAIL_VALIDATION,
  PHONE_VALIDATION,
  REQUIRED,
  ZIP_CODE_VALIDATION,
  STRING_VALIDATION_NOT_REQUIRED,
  EMAIL_VALIDATION_NOT_REQUIRED,
  STRING_VALIDATION_ALPHANUMERIC,
  STRING_VALIDATION_ALPHABETIC,
} from "../../../constants/Forms";
import { COLLECTIONS } from "../../../constants/General";
import { WholesaleEntity, wholesaleInitialValue } from "../../../constants/Wholesale";
import { useAlertContext } from "../../../context/Alert";
import { useApiClient } from "../../../context/ApiClient";
import { MultistepCreateForm } from "../MultistepCreateForm";
import { Page1 } from "./Page1";
import { Page2 } from "./Page2";
import { Page3 } from "./Page3";

export function WholesaleForm(): ReactElement {
  const apiClient = useApiClient().getClient();
  const useAlert = useAlertContext();

  const onSubmit = (values: WholesaleEntity) => {
    const faunaValues = {
      data: values,
    };
    create(apiClient, COLLECTIONS.Wholesale, faunaValues)
      .then(() => {
        console.log("Success!");
        useAlert.addAlert(true, "Success! You have completed your Wholesale Application.");
        setTimeout(() => navigate("/"), 2000);
      })
      .catch((error) => {
        console.log("Failed!");
        useAlert.addAlert(false, "Please try again. Something went wrong with the application." + error);
        setTimeout(() => window.location.reload(), 1500);
      });
  };

  return (
    <Box bg="white" pl="5vw" pr="5vw" ml="5vw" mr="5vw">
      <MultistepCreateForm
        initialValues={wholesaleInitialValue}
        onSubmit={onSubmit}
        submitDisabled={false}
        color="wholesale.primary"
        colorHover="wholesale.primary"
      >
        <Page1 validationSchema={validationSchemaPage1} />
        <Page2 validationSchema={validationSchemaPage2} />
        <Page3 validationSchema={validationSchemaPage3} />
      </MultistepCreateForm>
    </Box>
  );
}

const validationSchemaPage1 = object().shape({
  companyName: STRING_VALIDATION,
  companyEmail: EMAIL_VALIDATION,
  companyPhone: PHONE_VALIDATION,
  taxId: REQUIRED,
});

const validationSchemaPage2 = object().shape({
  billingAddress: STRING_VALIDATION_ALPHANUMERIC,
  billingCity: STRING_VALIDATION_ALPHABETIC,
  billingState: REQUIRED,
  billingZip: ZIP_CODE_VALIDATION,
  shippingAddress: STRING_VALIDATION_ALPHANUMERIC,
  shippingCity: STRING_VALIDATION_ALPHABETIC,
  shippingState: REQUIRED,
  shippingZip: ZIP_CODE_VALIDATION,
});

const validationSchemaPage3 = object().shape({
  employeeName1: STRING_VALIDATION_NOT_REQUIRED,
  employeeEmail1: EMAIL_VALIDATION_NOT_REQUIRED,
  employeeName2: STRING_VALIDATION_NOT_REQUIRED,
  employeeEmail2: EMAIL_VALIDATION_NOT_REQUIRED,
  employeeName3: STRING_VALIDATION_NOT_REQUIRED,
  employeeEmail3: EMAIL_VALIDATION_NOT_REQUIRED,
});
