import axios from "axios";
import { Client, Expr, ExprArg, query } from "faunadb";
import { Entity, PaginatedResponse } from "../Types";
import { getUserReferencebyUserId } from "./user";

export const q = query;

export async function create(client: Client, collection: string, data: ExprArg): Promise<any> {
  return await client.query(q.Create(q.Collection(collection), data));
}
export async function update(client: Client, collection: string, referenceId: string, data: ExprArg): Promise<any> {
  return await client.query(q.Update(q.Ref(q.Collection(collection), referenceId), data));
}

export async function getEntity(client: Client, collection: string, referenceId: string): Promise<EntityResponse> {
  return await client.query(q.Get(q.Ref(q.Collection(collection), referenceId)));
}

export async function getReference(client: Client, index: string, term: string): Promise<any> {
  return await client.query(q.Select("ref", q.Get(q.Match(q.Index(index), term))));
}

export function indexMatch(client: Client, index: string, term: any): Promise<any> {
  return client.query(q.Get(q.Match(q.Index(index), term)));
}

export async function getByIndexes(
  client: Client,
  indexSearches: IndexSearch[],
  paginateOptions: object | undefined
): Promise<PaginatedResponse<any>> {
  const intersectionSets: Expr[] = [];
  indexSearches
    .filter((indexSearch) => indexSearch.value)
    .forEach((indexSearch) => {
      if (!Array.isArray(indexSearch.value)) {
        intersectionSets.push(q.Match(q.Index(indexSearch.index), indexSearch.value));
      } else {
        const indexSets: Expr[] = [];
        indexSearch.value.forEach((val) => {
          indexSets.push(q.Match(q.Index(indexSearch.index), val));
        });
        if (indexSearch.anyMatch) {
          intersectionSets.push(q.Union(indexSets));
        } else {
          indexSets.forEach((set) => intersectionSets.push(set));
        }
      }
    });

  // Do I need this lambda with the ref? Or can I just return terms?
  const response = await client.query(
    q.Map(q.Paginate(q.Intersection(intersectionSets), paginateOptions), q.Lambda(["binding", "ref"], q.Get(q.Var("ref"))))
  );
  return {
    data: response["data"],
    after: response["after"],
    before: response["before"],
  };
}

export async function uploadImage(image: Blob, key: string, onSuccess: (presignedUrl: string) => void, onFailure: (failedResponse: any) => void) {
  const bucket = "roleplay-revolution-user-pdfs231821-prd";
  const reader = new FileReader();
  reader.readAsDataURL(image);

  reader.onloadend = function () {
    const config = {
      headers: {
        "Content-Type": "image/jpeg",
      },
    };

    axios
      .post(`https://rl1w7f46wf.execute-api.us-east-1.amazonaws.com/compress?bucket=${bucket}&key=public/${key}`, reader.result, config)
      .then((response) => onSuccess(response.data))
      .catch((error) => onFailure(error));
  };
}

export async function createEntity(client: Client, userId: string, fileS3Key: string, collection: string, entity: Entity): Promise<any> {
  const userRef = await getUserReferencebyUserId(client, userId);
  entity.createdAt = new Date().toISOString();
  entity.user = userRef;
  entity.fileS3Key = fileS3Key;
  entity.isPublished = false;
  entity.collections = 0;

  const response = await create(client, collection, {
    data: entity,
  });
  return response;
}

export async function updateEntity(client: Client, referenceId: string, collection: string, data: Entity): Promise<any> {
  const response = await update(client, collection, referenceId, {
    data: data,
  });
  return response;
}

export async function publish(client: Client, collection: string, referenceId: string, userId: string) {
  const response = await getEntity(client, collection, referenceId);
  const previewS3Key = response.data.previewS3Key;
  const fileS3Key = response.data.fileS3Key;
  const collected = response.data.collected || false;

  const monsterReference = response.ref;

  if (previewS3Key === "") {
    throw new Error("A monster must have an image to be published");
  }

  if (fileS3Key.split("/")[0] !== userId) {
    throw new Error("You must be the creator of this monster to publish it");
  }

  if (collected) {
    throw new Error("A monster cannot be published if it was collected from another user");
  }

  const modest = await isModestImage(previewS3Key);
  if (!modest.data) {
    throw new Error(`The image used is not acceptable for publishing`);
  }

  client.query(q.Update(monsterReference, { data: { isPublished: true } }));
}

export function isModestImage(previewS3Key: string) {
  const bucket = "roleplay-revolution-user-pdfs231821-prd";
  return axios.get(`https://rl1w7f46wf.execute-api.us-east-1.amazonaws.com/modest?bucket=${bucket}&key=public/${previewS3Key}`);
}

export async function incrementEntityCollection(client: Client, ref: ExprArg): Promise<any> {
  const monsterObject = await client.query(q.Get(ref));
  let collections = monsterObject.data.collections;
  collections = collections + 1;
  return await client.query(q.Update(ref, { data: { collections: collections } }));
}

export interface IndexSearch {
  index: string;
  value: string | number | boolean | Expr | string[] | number[] | Expr[] | undefined;
  anyMatch?: boolean;
}

export interface EntityResponse {
  data: Entity;
  ref: any;
}
