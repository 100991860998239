import { Box } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { MyNavbar } from "../components/common/navbar/MyNavbar";
import { WholesaleForm } from "../components/create_homebrew/wholesale/WholesaleForm";
import { Break } from "../components/styled/Break";
import { useAlertContext } from "../context/Alert";

export default function test(): ReactElement {
  const alertContext = useAlertContext();

  return (
    <Box bg="wholesale.background" minH="100vh">
      <MyNavbar />
      {alertContext.alert}
      <Break height="5vh" />
      <WholesaleForm />
    </Box>
  );
}
