import { SimpleGrid } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { isDesktopHook } from "../../../Hooks";
import { InputField } from "../../common/form_fields/InputField";
import { Break } from "../../styled/Break";
import { Title } from "../../styled/Text";
import { ImageUploadButton } from "../image_upload/ImageUploadButton";

export function Page1({ validationSchema }: { validationSchema: any }): ReactElement {
  const isDesktop = isDesktopHook();

  return (
    <>
      <Title text="Wholesale Application" fontSize={isDesktop ? "2.3rem" : "2rem"} />
      <InputField label="Company Name" name="companyName" borderHoverColor="wholesale.primaryHover" borderColor="wholesale.primary" />
      <Break height="3vh" />
      <SimpleGrid columns={{ lg: 3, sm: 1 }} gap={4}>
        <InputField label="Email" name="companyEmail" borderHoverColor="wholesale.primaryHover" borderColor="wholesale.primary" />
        <InputField
          label="Company Phone"
          name="companyPhone"
          placeholder="XXX-XXX-XXXX"
          borderHoverColor="wholesale.primaryHover"
          borderColor="wholesale.primary"
        />
        <InputField label="Tax Id Number" name="taxIdNumber" borderHoverColor="wholesale.primaryHover" borderColor="wholesale.primary" />
      </SimpleGrid>
      <Break height="5vh" />
    </>
  );
}
