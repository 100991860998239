import { AddIcon } from "@chakra-ui/icons";
import { HStack, SimpleGrid, Button, Box } from "@chakra-ui/react";
import React, { ReactElement, useState } from "react";
import { isDesktopHook } from "../../../Hooks";
import { InputField } from "../../common/form_fields/InputField";
import { Break } from "../../styled/Break";
import { Title } from "../../styled/Text";
import { FormikStep } from "../FormikStepper";

export function Page3({ validationSchema }: { validationSchema: any }): ReactElement {
  const [employees, setEmployees] = useState(1);
  const maxEmployees = 3;

  const isDesktop = isDesktopHook();

  const renderEmployees = () => {
    const employeeElements = [];
    for (let i = 1; i <= employees; i++) {
      employeeElements.push(
        <HStack mt="2vh" key={`employee element${i}`}>
          <SimpleGrid columns={{ lg: 2, sm: 1 }} gap={4} width="100%">
            <InputField label="Name" name={`employeeName${i}`} borderColor="wholesale.primary" borderHoverColor="wholesale.primaryHoverColor" />
            <InputField label="Email" name={`employeeEmail${i}`} borderColor="wholesale.primary" borderHoverColor="wholesale.primaryHoverColor" />
          </SimpleGrid>
          {i === employees && employees <= maxEmployees ? (
            <Button bg="wholesale.primary" top="1.5vh" onClick={() => setEmployees(employees + 1)} w="2vw">
              <AddIcon />
            </Button>
          ) : (
            <Box w="2vw" />
          )}
        </HStack>
      );
    }
    return employeeElements;
  };

  return (
    <FormikStep label={""} submitDisabled={false} color={"wholesale.primary"} colorHover={"wholesale.primaryHover"}>
      <Title text={isDesktop ? "Employees Authorized to Buy" : "Authorized to Buy"} fontSize={isDesktop ? "2.3rem" : "2rem"} />
      {isDesktop ? (
        renderEmployees()
      ) : (
        <>
          <InputField
            label={isDesktop ? "Name" : "Employee 1"}
            name={`employeeName1`}
            borderColor="wholesale.primary"
            borderHoverColor="wholesale.primaryHoverColor"
          />
          <InputField label="Email" name={`employeeEmail1`} borderColor="wholesale.primary" borderHoverColor="wholesale.primaryHoverColor" />
          <Break height="5vh" />
          {/*  */}{" "}
          <InputField
            label={isDesktop ? "Name" : "Employee 2"}
            name={`employeeName2`}
            borderColor="wholesale.secondary"
            borderHoverColor="wholesale.secondaryHoverColor"
          />
          <InputField label="Email" name={`employeeEmail2`} borderColor="wholesale.secondary" borderHoverColor="wholesale.secondaryHoverColor" />
          <Break height="5vh" />
          <InputField
            label={isDesktop ? "Name" : "Employee 3"}
            name={`employeeName3`}
            borderColor="wholesale.primary"
            borderHoverColor="wholesale.primaryHoverColor"
          />
          <InputField label="Email" name={`employeeEmail3`} borderColor="wholesale.primary" borderHoverColor="wholesale.primaryHoverColor" />
        </>
      )}
      <Break height="5vh" />
    </FormikStep>
  );
}
