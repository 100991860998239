import { Client } from "faunadb";
import { getReference } from "./common";

// Get
export async function getUserReferencebyUserId(client: Client, userId: string): Promise<any> {
  return getReference(client, "user_by_id_get_reference", userId);
}

export async function getUserReferenceByUsername(client: Client, username: string | undefined): Promise<any> {
  return getReference(client, "user_by_username_get_reference", username || "");
}
